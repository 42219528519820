import C from './constants';
import { combineReducers } from 'redux';

export const isAuthenticated = (state = {}, action) =>
    (action.type === C.UPDATE_AUTH_FLAG) ? action.payload : state

export const userInfo = (state = {}, action) =>
    (action.type === C.UPDATE_USER_INFO) ? action.payload : state

export const config = (state = {}, action) =>
    (action.type === C.UPDATE_CONFIG_INFO) ? action.payload : state

export const configParams = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_CONFIGPARAMS:
            return action.payload;
        default:
            return state;
    }
}

export const userFetching = (state = false, action) => {

    switch (action.type) {

        case C.USER_DETAILS_FETCHING:
            return true

        case C.CANCEL_USER_DETAILS_FETCHING:
            return false

        default:
            return state
    }

}

export const configApiDone = (state = false, action) => {

    switch (action.type) {

        case C.SET_CONFIGURATION_API:
            return true

        case C.UNSET_CONFIGURATION_API:
            return false

        default:
            return state
    }

}

export const chartsOverviewApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_CHARTS_OVERVIEW_API:
            return true

        case C.UNSET_CHARTS_OVERVIEW_API:
            return false

        default:
            return state
    }

}

export const workfileChartsOverviewApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_WOEKFILE_CHARTS_OVERVIEW_API:
            return true

        case C.UNSET_WORKFILE_CHARTS_OVERVIEW_API:
            return false

        default:
            return state
    }

}

export const filtersApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_FILTERS_API:
            return true

        case C.UNSET_FILTERS_API:
            return false

        default:
            return state
    }

}

export const workfileFiltersApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_FILTERS_API:
            return true

        case C.UNSET_WORKFILE_FILTERS_API:
            return false

        default:
            return state
    }

}

export const patentApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_PATENTS_API:
            return true

        case C.UNSET_PATENTS_API:
            return false

        default:
            return state
    }

}

export const workfilePatentApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_PATENTS_API:
            return true

        case C.UNSET_WORKFILE_PATENTS_API:
            return false

        default:
            return state
    }

}

export const startUpApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_STARTUP_API:
            return true

        case C.UNSET_STARTUP_API:
            return false

        default:
            return state
    }

}

export const workfileStartUpApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_STARTUP_API:
            return true

        case C.UNSET_WORKFILE_STARTUP_API:
            return false

        default:
            return state
    }

}

export const patentPageApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_PATENTS_PAGE_API:
            return true

        case C.UNSET_PATENTS_PAGE_API:
            return false

        default:
            return state
    }

}

export const worfkilePatentPageApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_PATENTS_PAGE_API:
            return true

        case C.UNSET_WORKFILE_PATENTS_PAGE_API:
            return false

        default:
            return state
    }

}

export const ccTaxonomyApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_CC_TAXONOMY_API:
            return true

        case C.UNSET_CC_TAXONOMY_API:
            return false

        default:
            return state
    }

}

export const workfileCcTaxonomyApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_CC_TAXONOMY_API:
            return true

        case C.UNSET_WORKFILE_CC_TAXONOMY_API:
            return false

        default:
            return state
    }

}

export const cpcTaxonomyApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_CPC_TAXONOMY_API:
            return true

        case C.UNSET_CPC_TAXONOMY_API:
            return false

        default:
            return state
    }

}

export const corporateTreeApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_CORPORATE_TREE_API:
            return true

        case C.UNSET_CORPORATE_TREE_API:
            return false

        default:
            return state
    }

}

export const cpcTreeApi = (state = false, action) => {

    switch (action.type) {

        case C.SET_CPC_TREE_API:
            return true

        case C.UNSET_CPC_TREE_API:
            return false

        default:
            return state
    }

}

export const corporateTreeStatus = (state = false, action) => {

    switch (action.type) {

        case C.SET_CORPORATE_TREE_STATUS:
            return true

        case C.UNSET_CORPORATE_TREE_STATUS:
            return false

        default:
            return state
    }

}

export const cpcTreeStatus = (state = false, action) => {

    switch (action.type) {

        case C.SET_CPC_TREE_STATUS:
            return true

        case C.UNSET_CPC_TREE_STATUS:
            return false

        default:
            return state
    }

}

export const scrollTop = (state = false, action) => {

    switch (action.type) {

        case C.SET_SCROLL_TOP_FLAG:
            return true

        case C.UNSET_SCROLL_TOP_FLAG:
            return false

        default:
            return state
    }

}

export const workfileScrollTop = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_SCROLL_TOP_FLAG:
            return true

        case C.UNSET_WORKFILE_SCROLL_TOP_FLAG:
            return false

        default:
            return state
    }

}

export const isChartsFullDataUptoDate = (state = false, action) => {

    switch (action.type) {

        case C.SET_ANALYTICS_FULL_DATA_UPTO_DATE:
            return true

        case C.UNSET_ANALYTICS_FULL_DATA_UPTO_DATE:
            return false

        default:
            return state
    }

}

export const isWorkfileChartsFullDataUptoDate = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_ANALYTICS_FULL_DATA_UPTO_DATE:
            return true

        case C.UNSET_WORKFILE_ANALYTICS_FULL_DATA_UPTO_DATE:
            return false

        default:
            return state
    }

}

export const isAnalyticsPanelUpdated = (state = false, action) => {

    switch (action.type) {

        case C.UPDATE_ANALYTICS_PANEL_DATA:
            return true

        case C.CLEAR_ANALYTICS_PANEL_DATA:
            return false

        default:
            return state
    }

}

export const isPatentsPanelUpdated = (state = false, action) => {

    switch (action.type) {

        case C.UPDATE_PATENTS_PANEL_DATA:
            return true

        case C.CLEAR_PATENTS_PANEL_DATA:
            return false

        default:
            return state
    }

}

export const receivedFreshTaxonomyCC = (state = false, action) => {

    switch (action.type) {

        case C.SET_FRESH_TAXONOMY_CC:
            return true

        case C.UNSET_FRESH_TAXONOMY_CC:
            return false

        default:
            return state
    }

}

export const receivedWorkfileFreshTaxonomyCC = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_FRESH_TAXONOMY_CC:
            return true

        case C.UNSET_WORKFILE_FRESH_TAXONOMY_CC:
            return false

        default:
            return state
    }

}

export const receivedFreshTaxonomyCPC = (state = false, action) => {

    switch (action.type) {

        case C.SET_FRESH_TAXONOMY_CPC:
            return true

        case C.UNSET_FRESH_TAXONOMY_CPC:
            return false

        default:
            return state
    }

}

export const isFieldSearchPanelOpened = (state = false, action) => {

    switch (action.type) {

        case C.ENABLE_FIELD_SEARCH:
            return true

        case C.CLOSE_FIELD_SEARCH:
            return false

        default:
            return state
    }

}

export const isWorkfileFieldSearchPanelOpened = (state = false, action) => {

    switch (action.type) {

        case C.ENABLE_WORKFILE_FIELD_SEARCH:
            return true

        case C.CLOSE_WORKFILE_FIELD_SEARCH:
            return false

        default:
            return state
    }

}

export const basicQuery = (state = [], action) => {
    switch (action.type) {
        case C.BASIC_QUERY:
            return action.payload;
        default:
            return state;
    }
}

export const workfileBasicQuery = (state = [], action) => {
    switch (action.type) {
        case C.WORKFILE_BASIC_QUERY:
            return action.payload;
        default:
            return state;
    }
}

export const formattedQuery = (state = {}, action) => {
    switch (action.type) {
        case C.FORMATTED_QUERY:
            return action.payload;
        default:
            return state;
    }
}

export const workfileFormattedQuery = (state = {}, action) => {
    switch (action.type) {
        case C.WORKFILE_FORMATTED_QUERY:
            return action.payload;
        default:
            return state;
    }
}

export const fieldQuery = (state = [], action) => {
    switch (action.type) {
        case C.FIELD_QUERY:
            return action.payload;
        default:
            return state;
    }
}

export const fieldQueryData = (state = [], action) => {
    switch (action.type) {
        case C.FIELD_QUERY_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const workfileFieldQuery = (state = [], action) => {
    switch (action.type) {
        case C.WORKFILE_FIELD_QUERY:
            return action.payload;
        default:
            return state;
    }
}



export const queryMetaData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_QUERY_METADATA:
            return action.payload;
        default:
            return state;
    }
}

export const workfileQueryMetaData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_QUERY_METADATA:
            return action.payload;
        default:
            return state;
    }
}

export const combineQueryFlag = (state = false, action) => {

    switch (action.type) {

        case C.SET_COMBINE_QUERY_FLAG:
            return true

        case C.UNSET_COMBINE_QUERY_FLAG:
            return false

        default:
            return state
    }

}

export const workfileCombineQueryFlag = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_COMBINE_QUERY_FLAG:
            return true

        case C.UNSET_WORKFILE_COMBINE_QUERY_FLAG:
            return false

        default:
            return state
    }

}

export const groupBy = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_GROUP_BY:
            return action.payload ? action.payload : "ucid";
        default:
            return state;
    }
}

export const groupFieldOptions = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_GROUP_FIELD_OPTIONS:
            return action.payload ? action.payload : "ucid";
        default:
            return state;
    }
}

export const workfileGroupBy = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_GROUP_BY:
            return action.payload ? action.payload : "ucid";
        default:
            return state;
    }
}

export const filters = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_FILTERS:
            return action.payload;
        default:
            return state;
    }
}

export const workfileFilters = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_FILTERS:
            return action.payload;
        default:
            return state;
    }
}

export const filtersDataStatus = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_FILTERS_DATA_STATUS:
            return action.payload;
        default:
            return state;
    }
}
export const refPatentDetails = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_REF_PATENT_DETAILS:
            return action.payload;
        default:
            return state;
    }
}

export const sidebyside = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_SIDEBYSIDE:
            return action.payload;
        default:
            return state;
    }
}

export const workfileFiltersDataStatus = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_FILTERS_DATA_STATUS:
            return action.payload;
        default:
            return state;
    }
}

export const customizePatentDataStatus = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_CUSTOMIZE_PATENT_DATA_STATUS:
            return action.payload;
        default:
            return state;
    }
}

export const filtersCPCCounts = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_FILTERS_CPC_COUNT:
            return action.payload;
        default:
            return state;
    }
}

export const filtersCorpCounts = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_FILTERS_CORP_COUNT:
            return action.payload;
        default:
            return state;
    }
}

export const applyFiltersFlag = (state = false, action) => {

    switch (action.type) {

        case C.SET_APPLY_FILTERS_FLAG:
            return true

        case C.UNSET_APPLY_FILTERS_FLAG:
            return false

        default:
            return state
    }

}

export const workfileApplyFiltersFlag = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_APPLY_FILTERS_FLAG:
            return true

        case C.UNSET_WORKFILE_APPLY_FILTERS_FLAG:
            return false

        default:
            return state
    }

}

export const appliedFilters = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_APPLIED_FILTERS:
            return action.payload;
        default:
            return state;
    }
}

export const appliedSimilarityFilters = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_APPLIED_SIMILARITY_FILTER:
            return action.payload;
        default:
            return state;
    }
}

export const urlFilters = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_URL_FILTERS:
            return action.payload;
        default:
            return state;
    }
}

export const workfileAppliedFilters = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_APPLIED_FILTERS:
            return action.payload;
        default:
            return state;
    }
}

export const appliedTaxonomy = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_APPLIED_TAXONOMY:
            return action.payload;
        default:
            return state;
    }
}

export const workfileAppliedTaxonomy = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_APPLIED_TAXONOMY:
            return action.payload;
        default:
            return state;
    }
}

export const dummyAppliedTaxonomy = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_DUMMY_APPLIED_TAXONOMY:
            return action.payload;
        default:
            return state;
    }
}

export const workfileDummyAppliedTaxonomy = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_DUMMY_APPLIED_TAXONOMY:
            return action.payload;
        default:
            return state;
    }
}

export const selectedTaxonomy = (state = {}, action) => {
    switch (action.type) {
        case C.UPDATE_SELECTED_TAXONOMY:
            return action.payload;
        default:
            return state;
    }
}

export const selectedFiltersType = (state = {}, action) => {
    switch (action.type) {
        case C.UPDATE_SELECTED_FILTERS_TYPE:
            return action.payload;
        default:
            return state;
    }
}

export const filtersFacetsLimit = (state = {}, action) => {
    switch (action.type) {
        case C.UPDATE_FILTERS_FACETS_LIMIT:
            return action.payload;
        default:
            return state;
    }
}

export const workfileFiltersFacetsLimit = (state = {}, action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_FILTERS_FACETS_LIMIT:
            return action.payload;
        default:
            return state;
    }
}

export const chartsFullData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_CHARTS_FULL_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const companyChartsFullData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_COMPANY_CHARTS_FULL_DATA:
            return action.payload;
        default:
            return state;
    }
}



export const workfileChartsFullData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_CHARTS_FULL_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const chartFullScreen = (state = false, action) => {

    switch (action.type) {

        case C.SET_CHART_FULL_SCREEN:
            return true

        case C.UNSET_CHART_FULL_SCREEN:
            return false

        default:
            return state
    }

}

export const workfileChartFullScreen = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_CHART_FULL_SCREEN:
            return true

        case C.UNSET_WORKFILE_CHART_FULL_SCREEN:
            return false

        default:
            return state
    }

}

export const chartsOverviewData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_CHARTS_OVERVIEW_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const workfileChartsOverviewData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_CHARTS_OVERVIEW_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const patentsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_PATENTS_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const importPatentsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_IMPORT_PATENTS_DATA:
            return action.payload;
        default:
            return state;
    }
}


export const workfilePatentsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_PATENTS_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const startUpsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_STARTUP_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const workfileStartUpsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_STARTUP_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const patentsSelectedCount = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_PATENTS_SELECTED_COUNT:
            return action.payload;
        default:
            return state;
    }
}

export const workfilePatentsSelectedCount = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_PATENTS_SELECTED_COUNT:
            return action.payload;
        default:
            return state;
    }
}

export const workfileRatingMetaData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_WORKFILE_RATING_METADATA:
            return action.payload;
        default:
            return state;
    }
}

export const workfileId = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_WORKFILE_ID:
            return action.payload;
        default:
            return state;
    }
}

export const workfileDetails = (state = [], action) =>{
    switch (action.type) {
        case C.UPDATE_WORKFILE_WORKFILE_DETAILS:
            return action.payload;
        default:
            return state;
    }
}

export const pageNumber = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_PATENTS_PAGE_NUMBER:
            return action.payload;
        default:
            return state;
    }
}

export const workfilePageNumber = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_PATENTS_PAGE_NUMBER:
            return action.payload;
        default:
            return state;
    }
}

export const patentSortOption = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_PATENTS_SORT_OPTION:
            return action.payload;
        default:
            return state;
    }
}

export const workfilePatentSortOption = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_PATENTS_SORT_OPTION:
            return action.payload;
        default:
            return state;
    }
}

export const patentDeatailsIndex = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_PATENT_DETAIL_INDEX:
            return action.payload;
        default:
            return state;
    }
}

export const workfilePatentDeatailsIndex = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_PATENT_DETAIL_INDEX:
            return action.payload;
        default:
            return state;
    }
}

export const documentsUrlBeforeDetails = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_DOCUMENT_URL_BEFORE_DETAILS:
            return action.payload;
        default:
            return state;
    }
}

export const selectAllPages = (state = false, action) => {

    switch (action.type) {

        case C.SET_SELECT_ALL_PAGES:
            return true

        case C.UNSET_SELECT_ALL_PAGES:
            return false

        default:
            return state
    }

}

export const workfileSelectAllPages = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_SELECT_ALL_PAGES:
            return true

        case C.UNSET_WORKFILE_SELECT_ALL_PAGES:
            return false

        default:
            return state
    }

}

export const selectAllInCurrentPage = (state = false, action) => {

    switch (action.type) {

        case C.SET_SELECT_ALL_CURRENT_PAGE:
            return true

        case C.UNSET_SELECT_ALL_CURRENT_PAGE:
            return false

        default:
            return state
    }

}

export const selectWorkfileAllInCurrentPage = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_SELECT_ALL_CURRENT_PAGE:
            return true

        case C.UNSET_WORKFILE_SELECT_ALL_CURRENT_PAGE:
            return false

        default:
            return state
    }

}

export const selectedPages = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_SELECTED_PAGES:
            return action.payload;
        default:
            return state;
    }
}

export const workfileSelectedPages = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_SELECTED_PAGES:
            return action.payload;
        default:
            return state;
    }
}

export const synonymsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_SYNONYMS_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const workfileSynonymsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_SYNONYMS_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const appliedSynonymsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_SYNONYMS_APPLIED_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const appliedWorkfileSynonymsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_SYNONYMS_APPLIED_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const semanticsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_SEMANTICS_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const workfileSemanticsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_SEMANTICS_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const taxonomyCC = (state = [], action) => {
    switch (action.type) {
        case C.TAXONOMY_CC:
            return action.payload;
        default:
            return state;
    }
}

export const workfileTaxonomyCC = (state = [], action) => {
    switch (action.type) {
        case C.WORKFILE_TAXONOMY_CC:
            return action.payload;
        default:
            return state;
    }
}

export const taxonomyCPC = (state = [], action) => {
    switch (action.type) {
        case C.TAXONOMY_CPC:
            return action.payload;
        default:
            return state;
    }
}

export const historyQuery = (state = [], action) => {
    switch (action.type) {
        case C.HISTORY_QUERY:
            return action.payload;
        default:
            return state;
    }
}

export const cursorDetails = (state = [], action) => {
    switch (action.type) {
        case C.CURSOR_DETAILS:
            return action.payload;
        default:
            return state;
    }
}

export const workfileCursorDetails = (state = [], action) => {
    switch (action.type) {
        case C.WORKFILE_CURSOR_DETAILS:
            return action.payload;
        default:
            return state;
    }
}

export const errors = (state = [], action) => {
    switch (action.type) {
        case C.ADD_ERROR:
            return [action.payload];
        case C.CLEAR_ERROR:
            return [];
        default:
            return state;
    }
}

export const errorNotification = (state = false, action) => {

    switch (action.type) {

        case C.SET_ERROR_NOTIFICATION:
            return true

        case C.UNSET_ERROR_NOTIFICATION:
            return false

        default:
            return state
    }

}

export const exportList = (state = [], action) => {
    switch (action.type) {
        case C.ADD_EXPORT_FILE:
            return [...state, action.payload];
        case C.REMOVE_EXPORT_FILE:
            var temp = [];
            state.map((each_file, index) => {
                if(action.payload !== each_file)
                    temp.push(each_file);
                return true; //Dummy return
            })
            return temp;
        default:
            return state;
    }
}

export const dashboardList = (state = [], action) => {
    switch (action.type) {
        case C.ADD_DASHBOARD_FILE:
            return [...state, action.payload];
        case C.REMOVE_DASHBOARD_FILE:
            var temp = [];
            state.map((each_file, index) => {
                if(action.payload !== each_file)
                    temp.push(each_file);
                return true; //Dummy return
            })
            return temp;
        default:
            return state;
    }
}

export const searchFromHistoryFlag = (state = [], action) => {
    switch (action.type) {
        case C.SET_SEARCH_FLAG_FROM_HISTORY:
            return action.payload;
        default:
            return state;
    }
}

export const filtersFromHistory = (state = [], action) => {
    switch (action.type) {
        case C.SET_FILTERS_FROM_HISTORY:
            return action.payload;
        default:
            return state;
    }
}

export const taxonomyFromHistory = (state = [], action) => {
    switch (action.type) {
        case C.SET_TAXONOMY_FROM_HISTORY:
            return action.payload;
        default:
            return state;
    }
}

export const initialLoad = (state = [], action) => {
    switch (action.type) {
        case C.SET_INITIAL_LOAD:
            return action.payload;
        default:
            return state;
    }
}

export const workfileInitialLoad = (state = [], action) => {
    switch (action.type) {
        case C.SET_WORKFILE_INITIAL_LOAD:
            return action.payload;
        default:
            return state;
    }
}

export const updateBasicQueryFromFiltersFlag = (state = false, action) => {

    switch (action.type) {

        case C.SET_BASIC_QUERY_FROM_FILTERS_FLAG:
            return true

        case C.UNSET_BASIC_QUERY_FROM_FILTERS_FLAG:
            return false

        default:
            return state
    }

}

export const updateWorkfileBasicQueryFromFiltersFlag = (state = false, action) => {

    switch (action.type) {

        case C.SET_WORKFILE_BASIC_QUERY_FROM_FILTERS_FLAG:
            return true

        case C.UNSET_WORKFILE_BASIC_QUERY_FROM_FILTERS_FLAG:
            return false

        default:
            return state
    }

}

export const createAlertData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_CREATE_ALERT_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const showfullchartsFlag = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_SHOW_FULLCHARTS_FLAG:
            return action.payload;
        default:
            return state;
    }
}

export const patent_position_ref = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_PATENT_POSITION_REF:
            return action.payload;
        default:
            return state;
    }
}

export const workfilePatent_position_ref = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_PATENT_POSITION_REF:
            return action.payload;
        default:
            return state;
    }
}

export const highlighted_keywords = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_HIGHLIGHTED_KEYWORDS:
            return action.payload;
        default:
            return state;
    }
}

export const workfile_highlighted_keywords = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_WORKFILE_HIGHLIGHTED_KEYWORDS:
            return action.payload;
        default:
            return state;
    }
}

export const highlighted_wordforms = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_HIGHLIGHTED_WORDFORMS:
            return action.payload;
        default:
            return state;
    }
}

export const isKeywordHighlightOn = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_HIGHLIGHT_ON_OFF_FLAG:
            return action.payload;
        default:
            return state;
    }
}

export const viewedPatents = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_VIEWED_PATENTS:
            return action.payload;
        default:
            return state;
    }
}

export const SaveToWorkfileApi = (state = {}, action) => {
    switch (action.type) {
        case C.UPDATE_SAVE_TO_WORKFILE_API:
            return action.payload;
        default:
            return state;
    }
}

export const wordformsData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_WORDFORMS_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const patentsStatsData = (state = [], action) =>{
    switch (action.type){
        case C.UPDATE_PATENTS_STATS_DATA:
            return action.payload;
        default:
            return state;
    }

}

export const workfilePatentsStatsData = (state = [], action) =>{
    switch (action.type){
        case C.UPDATE_WORKFILE_PATENTS_STATS_DATA:
            return action.payload;
        default:
            return state;
    }

}

export const hiddenCitationsData = (state = [], action) =>{
    switch (action.type){
        case C.UPDATE_HIDDEN_CITATIONS_DATA:
            return action.payload;
        default:
            return state;
    }

}

export const hiddenCitnCurLevel = (state = [], action) =>{
    switch(action.type){
        case C.UPDATE_HIDDEN_CITN_CUR_LEVEL:
            return action.payload;
        default:
            return state;
    }
}

export const hiddenCitnEachLevel = (state = [], action) =>{
    switch(action.type){
        case C.UPDATE_HIDDEN_CITN_EACH_LEVEL:
            return action.payload;
        default:
            return state;
    }
}

export const hiddenCitnPageNumber = (state = [], action) =>{
    switch(action.type){
        case C.UPDATE_HIDDEN_CITN_PAGE_NUMBER:
            return action.payload;
        default:
            return state;
    }
}

export const hiddenCitnPatentsSelectedCount = (state = [], action) =>{
    switch(action.type){
        case C.UPDATE_HIDDEN_CITN_PATENTS_SELECTED_COUNT:
            return action.payload;
        default:
            return state;
    }
}

export const hiddenCitnSelectAllPages = (state = false, action) => {

    switch (action.type) {

        case C.SET_HIDDEN_CITN_SELECT_ALL_PAGES:
            return true

        case C.UNSET_HIDDEN_CITN_SELECT_ALL_PAGES:
            return false

        default:
            return state
    }

}

export const selectHiddenCitnAllInCurrentPage = (state = false, action) => {

    switch (action.type) {

        case C.SET_HIDDEN_CITN_SELECT_ALL_CURRENT_PAGE:
            return true

        case C.UNSET_HIDDEN_CITN_SELECT_ALL_CURRENT_PAGE:
            return false

        default:
            return state
    }

}

export const hiddenCitnSelectedPages = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_HIDDEN_CITN_SELECTED_PAGES:
            return action.payload;
        default:
            return state;
    }
}

export const missingPatentsData = (state = [], action) =>{
    switch (action.type) {
        case C.UPDATE_MISSING_PATENTS_DATA:
            return action.payload;
        default:
            return state;
    }
}


export const orderingData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_ORDERING_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const applyMissingPatentsFlag = (state = [], action) =>{
    switch (action.type) {

        case C.SET_APPLY_MISSING_PATENTS_FLAG:
            return true

        case C.UNSET_APPLY_MISSING_PATENTS_FLAG:
            return false

        default:
            return state
    }
}

export const groupSort = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_GROUP_SORT:
            return action.payload;
        default:
            return state;
    }
}

export const addUserSynonymsFlag = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_ADD_USER_SYNONYMS_FLAG:
            return action.payload;
        default:
            return state;
    }
}

export const runClonedQueryFlag = (state = [], action) =>{
    switch (action.type) {
        case C.UPDATE_RUN_CLONED_QUERY_FLAG:
            return action.payload;
        default:
            return state;
    }
}

export const devControls = (state = [], action) =>{
    switch (action.type) {
        case C.UPDATE_DEV_CONTROLS:
            return action.payload;
        default:
            return state;
    }
}

export const sessionGroupData = (state = [], action) =>{
    switch (action.type) {
        case C.UPDATE_SESSION_GROUP_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const fetchFilterKeys = (state = [], action) =>{
    switch (action.type) {
        case C.UPDATE_FETCH_FILTER_KEYS:
            return action.payload;
        default:
            return state;
    }
}

export const userGroups = (state = [], action) =>{
    switch (action.type) {
        case C.UPDATE_USER_GROUPS:
            return action.payload;
        default:
            return state;
    }
}

export const projectId = (state = false, action) => {
    switch(action.type) {
        case C.UPDATE_PROJECT_ID:
            return action.payload;
        default:
            return state;
    }
}

export const documentsMaxScore = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_DOCUMENT_MAX_SCORE:
            return action.payload;
        default:
            return state;
    }
}

export const language = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_LANGUAGE:
            return action.payload;
        default:
            return state;
    }
}

export const documentsPath = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_DOCUMENTSPATH:
            return action.payload;
        default:
            return state;
    }
}

export const dataSourceData = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_DATASOURCE_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const expandCountHistory = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_EXPAND_COUNT_HISTORY:
            return action.payload;
        default:
            return state;
    }
}



export const yearTableFilterFlag = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_YEAR_TABLE_FILTER_FLAG:
            return action.payload;
        default:
            return state;
    }
}


export const taxonomyPreviousActiveTab = (state = [], action) => {
    switch(action.type) {
        case C.UPDATE_TAXONOMY_PREVIOUS_ACTIVE_TAB:
            return action.payload;
        default:
            return state;
    }
}

export const documentType = (state = false, action) => {
    switch(action.type) {
        case C.UPDATE_DOCUMENT_TYPE:
            return action.payload;
        default:
            return state;
    }
}

export const detailsViewType = (state = false, action) => {
    switch(action.type) {
        case C.UPDATE_DETAILS_VIEW_TYPE:
            return action.payload;
        default:
            return state;
    }
}

export const connectorScreen = (state = false, action) => {
    switch(action.type) {
        case C.UPDATE_CONNECTOR_SCREEN:
            return action.payload;
        default:
            return state;
    }
}

export const connectorConfigParams = (state = false, action) => {
    switch(action.type) {
        case C.UPDATE_CONNECTOR_CONFIGPARAMS:
            return action.payload;
        default:
            return state;
    }
}

export const connectorConfig = (state = false, action) => {
    switch(action.type) {
        case C.UPDATE_CONNECTOR_CONFIG:
            return action.payload;
        default:
            return state;
    }
}

export const connectorInfo = (state = false, action) => {
    switch(action.type) {
        case C.UPDATE_CONNECTOR_INFO:
            return action.payload;
        default:
            return state;
    }
}

export const activeTab = (state = [], action) => {
    switch (action.type) {
        case C.UPDATE_ACTIVE_TAB:
            return action.payload;
        default:
            return state;
    }
}

export const documentTaggingOptions = (state = false, action) => {
    switch(action.type) {
        case C.UPDATE_DOCUMENT_TAGGING_OPTIONS:
            return action.payload;
        default:
            return state;
    }
}

export const showProjectSettingsFlag = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_PROJECT_SETTINGS_FLAG:
            return action.payload;
        default:
            return state;
    }
}

export const alertsStatusPath = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_ALERTS_STATUS_PATH:
            return action.payload;
        default:
            return state;
    }
}

const smartImportSheetsData = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_SMART_IMPORT_SHEETS_DATA:
            return action.payload;
        default:
            return state;
    }
}
const patentsProductsMappingData = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_PATENTS_PRODUCTS_MAPPING_DATA:
            return action.payload;
        default:
            return state;
    }
}

const isClaimChartsEnable = (state = false, action) => {
    switch (action.type) {

        case C.SET_CLAIM_CHARTS_ENABLE:
            return true

        case C.UNSET_CLAIM_CHARTS_ENABLE:
            return false

        default:
            return state
    }
}

const isProductsEnable = (state = false, action) => {
    switch (action.type) {

        case C.SET_PRODUCTS_ENABLE:
            return true

        case C.UNSET_PRODUCTS_ENABLE:
            return false

        default:
            return state
    }
}

const isLevel2AnalysisLoading = (state = false, action) => {
    switch (action.type) {

        case C.SET_LEVEL2_ANALYSIS_ENABLE:
            return true

        case C.UNSET_LEVEL2_ANALYSIS_ENABLE:
            return false

        default:
            return state
    }
}

const level2SelectedPatents = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_LEVEL2_SELECTED_PATENTS:
            return action.payload;
        default:
            return state;
    }
}

const isClaimChartGenerationLoading = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_CLAIM_CHART_GENERATION_LOADING:
            return action.payload;
        default:
            return state;
    }
}

const projectNotificationCount = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_PROJECT_NOTIFICATION_COUNT:
            return action.payload;
        default:
            return state;
    }
}

export const currentSearch = (state = false, action) => {
    
    switch(action.type) {
        case C.UPDATE_CURRENT_SEARCH:
            return action.payload;
        default:
            return state;
    }
}

export const isStoreSearchQueryRunning = (state =[], action) => {
    switch(action.type){
        case C.UPDATE_RUN_STORE_SEARCH_QUERY:
            return action.payload;
        default:
            return state;
    }
}

const claimChartsData = (state = false, action) => {
    switch (action.type) {
        case C.UPDATE_CLAIM_CHARTS_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const appliedStoredSearch = (state ={}, action) => {
    switch(action.type){
        case C.UPDATE_APPLIED_STORED_SEARCH:
            return action.payload;
        default:
            return state;
    }
}

export const searchQuery = (state = [], action) => {
    switch(action.type) {
        case C.UPDATE_SEARCH_QUERY_SEARCH:
            return action.payload;
        default:
            return state;
    }
}

export const fieldSearchPannelSource = (state = false, action) => {
    switch(action.type) {
        case C.UPDATE_FIELD_SEARCH_PANNEL_SOURCE:
            return action.payload;
        default:
            return state;
    }
}

export const viewQueryData = (state ={}, action) => {
    switch(action.type){
        case C.UPDATE_VIEW_QUERY_DATA:
            return action.payload;
        default:
            return state;
    }
}

export const loadingMoreFiltersFlag = (state = false, action) => {
    switch(action.type) {
        case C.UPDATE_LOADING_MORE_FILTERS_FLAG:
            return action.payload;
        default:
            return state;
    }
}


export default combineReducers({
    isAuthenticated,
    config,
    configParams,
    userData: combineReducers({
        userFetching,
        userInfo
    }),
    apiStatus: combineReducers({
        configApiDone,
        chartsOverviewApi,
        filtersApi,
        patentApi,
        startUpApi,
        patentPageApi,
        ccTaxonomyApi,
        cpcTaxonomyApi,
        isChartsFullDataUptoDate
    }),
    workfileApiStatus: combineReducers({
        chartsOverviewApi: workfileChartsOverviewApi,
        filtersApi: workfileFiltersApi,
        patentApi: workfilePatentApi,
        startUpApi: workfileStartUpApi,
        patentPageApi: worfkilePatentPageApi,
        ccTaxonomyApi: workfileCcTaxonomyApi,
        isChartsFullDataUptoDate: isWorkfileChartsFullDataUptoDate
    }),
    extraFiltersApiStatus: combineReducers({
        corporateTreeApi,
        cpcTreeApi
    }),
    extraFiltersStatus: combineReducers({
        corporateTreeStatus,
        cpcTreeStatus
    }),
    scrollStatus: combineReducers({
        scrollTop
    }),
    workfileScrollStatus: combineReducers({
        scrollTop: workfileScrollTop
    }),
    isAnalyticsPanelUpdated,
    isPatentsPanelUpdated,
    isFieldSearchPanelOpened,
    isWorkfileFieldSearchPanelOpened,
    basicQuery,
    workfileBasicQuery,
    formattedQuery,
    workfileFormattedQuery,
    fieldQuery,
    fieldQueryData,
    workfileFieldQuery,
    queryMetaData,
    workfileQueryMetaData,
    combineQueryFlag,
    workfileCombineQueryFlag,
    groupBy,
    groupFieldOptions,
    workfileGroupBy,
    filters,
    workfileFilters,
    filtersFacetsLimit,
    workfileFiltersFacetsLimit,
    filtersDataStatus,
    workfileFiltersDataStatus,
    refPatentDetails,
    sidebyside,
    customizePatentDataStatus,
    filtersCPCCounts,
    applyFiltersFlag,
    workfileApplyFiltersFlag,
    appliedFilters,
    appliedSimilarityFilters,
    workfileAppliedFilters,
    urlFilters,
    chartsOverviewData,
    workfileChartsOverviewData,
    chartsFullData,
    companyChartsFullData,
    workfileChartsFullData,
    chartFullScreen,
    workfileChartFullScreen,
    patentsData,
    workfilePatentsData,
    startUpsData,
    workfileStartUpsData,
    patentsSelectedCount,
    workfilePatentsSelectedCount,
    pageNumber,
    workfilePageNumber,
    workfileId,
    workfileDetails,
    workfileRatingMetaData,
    patentSortOption,
    workfilePatentSortOption,
    patentDeatailsIndex,
    workfilePatentDeatailsIndex,
    documentsUrlBeforeDetails,
    patentsSelection: combineReducers({
        selectAllPages,
        selectAllInCurrentPage,
        selectedPages
    }),
    workfilePatentsSelection: combineReducers({
        selectAllPages: workfileSelectAllPages,
        selectAllInCurrentPage: selectWorkfileAllInCurrentPage,
        selectedPages: workfileSelectedPages
    }),
    synonymsData,
    workfileSynonymsData,
    appliedSynonymsData,
    appliedWorkfileSynonymsData,
    semanticsData,
    workfileSemanticsData,
    receivedFreshTaxonomyCC,
    receivedWorkfileFreshTaxonomyCC,
    taxonomyCC,
    workfileTaxonomyCC,
    receivedFreshTaxonomyCPC,
    taxonomyCPC,
    appliedTaxonomy,
    workfileAppliedTaxonomy,
    dummyAppliedTaxonomy,
    workfileDummyAppliedTaxonomy,
    selectedTaxonomy,
    selectedFiltersType,
    cursorDetails,
    workfileCursorDetails,
    errors,
    errorNotification,
    exportList,
    dashboardList,
    searchFromHistoryFlag,
    filtersFromHistory,
    taxonomyFromHistory,
    initialLoad,
    workfileInitialLoad,
    updateBasicQueryFromFiltersFlag,
    updateWorkfileBasicQueryFromFiltersFlag,
    createAlertData,
    showfullchartsFlag,
    patent_position_ref,
    workfilePatent_position_ref,
    highlighted_keywords,
    workfile_highlighted_keywords,
    highlighted_wordforms,
    isKeywordHighlightOn,
    viewedPatents,
    SaveToWorkfileApi,
    wordformsData,
    patentsStatsData,
    workfilePatentsStatsData,
    hiddenCitationsData,
    hiddenCitnCurLevel,
    hiddenCitnEachLevel,
    hiddenCitnPageNumber,
    hiddenCitnPatentsSelectedCount,
    hiddenCitnPatentsSelection: combineReducers({
        selectAllPages: hiddenCitnSelectAllPages,
        selectAllInCurrentPage: selectHiddenCitnAllInCurrentPage,
        selectedPages: hiddenCitnSelectedPages
    }),
    missingPatentsData,
    applyMissingPatentsFlag,
    orderingData,
    groupSort,
    addUserSynonymsFlag,
    runClonedQueryFlag,
    devControls,
    sessionGroupData,
    fetchFilterKeys,
    userGroups,
    projectId,
    documentsMaxScore,
    language,
    documentsPath,
    expandCountHistory,
    yearTableFilterFlag,
    taxonomyPreviousActiveTab,
    dataSourceData,
    documentType,
    detailsViewType,
    connectorScreen,
    connectorConfigParams,
    connectorConfig,
    connectorInfo,
    activeTab,
    documentTaggingOptions,
    showProjectSettingsFlag,
    importPatentsData,
    alertsStatusPath,
    smartImportSheetsData,
    patentsProductsMappingData,
    isClaimChartsEnable,
    isProductsEnable,
    isLevel2AnalysisLoading,
    level2SelectedPatents,
    isClaimChartGenerationLoading,
    projectNotificationCount,
    currentSearch,
    isStoreSearchQueryRunning,
    claimChartsData,
    appliedStoredSearch,
    searchQuery,
    fieldSearchPannelSource,
    viewQueryData,
    loadingMoreFiltersFlag
})